import BaseApiService from '@/services/api/base.api-service';

const PATH = {
  MFA_GENERATION: '/auth/mfa/generate',
  MFA_ACTIVATION: '/auth/mfa/activate',
  MFA_RESET: '/auth/mfa/reset',
  IS_MFA_ENABLED: '/auth/mfa/enabled',
};

class MultiFactorAuthenticationService extends BaseApiService {
  generateMFASecret(params) {
    return this.get(`${PATH.MFA_GENERATION}/${params.token}`);
  }

  activateMFA(params) {
    return this.patch(`${PATH.MFA_ACTIVATION}/${params.activationToken}`, { secret: params.secret });
  }

  resetMFA(params) {
    return this.patch(`${PATH.MFA_RESET}/${params.resetToken}`, { secret: params.secret });
  }

  isMFAEnabled() {
    return this.get(`${PATH.IS_MFA_ENABLED}`);
  }
}

export default new MultiFactorAuthenticationService();
