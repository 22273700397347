<template>
  <v-app v-if="$route.name">
    <v-idle v-if="isLoggedIn && $route.name !== 'Login'" :duration="DURATION" class="idle" @idle="goToLogin" />
    <core-toolbar v-if="isLoggedIn && $route.name !== 'Login' && $route.name !== 'Guacamole Client'" />
    <core-drawer v-if="isLoggedIn && $route.name !== 'Login' && $route.name !== 'Guacamole Client'" />
    <core-view class="vertical-scroll" />
  </v-app>
</template>
<script>
import CoreView from '@/layout/View.vue';
import CoreToolbar from '@/layout/Toolbar.vue';
import CoreDrawer from '@/layout/Drawer.vue';
import { DURATION } from '@/constants';

export default {
  components: {
    CoreView,
    CoreToolbar,
    CoreDrawer,
  },
  data: () => ({
    DURATION,
  }),
  computed: {
    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn']();
    },
  },
  methods: {
    async goToLogin() {
      try {
        await this.$store.dispatch('auth/logout');
      } catch (e) {
        this.$log.debug(e);
      }
    },
  },
};
</script>

<style lang="scss">
.idle {
  display: none;
}
.vertical-scroll {
  overflow-y: auto !important;
  height: 250px;
}
</style>
