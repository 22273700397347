/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2024. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import MultiFactorAuthenticationService from '@/services/api/multi-factor-authentication.api-service';
import { set } from '@/utils/vuex';

export default {
  state: {
    mfaUrl: '',
    mfaSecret: '',
    isMFAEnabled: false,
  },
  mutations: {
    SET_MFA_URL: set('mfaUrl'),
    SET_MFA_SECRET: set('mfaSecret'),
    SET_IS_MFA_ENABLED: set('isMFAEnabled'),
  },
  getters: {
    mfaUrl: (state) => state.mfaUrl,
    mfaSecret: (state) => state.mfaSecret,
    isMFAEnabled: (state) => state.isMFAEnabled,
  },
  actions: {
    async generateMFASecret({ commit }, params) {
      const res = await MultiFactorAuthenticationService.generateMFASecret(params);
      commit('SET_MFA_URL', res.mfaUrl);
      commit('SET_MFA_SECRET', res.mfaSecret);
    },

    async activateMFA(_, params) {
      await MultiFactorAuthenticationService.activateMFA(params);
    },

    async resetMFA(_, params) {
      await MultiFactorAuthenticationService.resetMFA(params);
    },

    async isMFAEnabled({ commit }) {
      const res = await MultiFactorAuthenticationService.isMFAEnabled();
      commit('SET_IS_MFA_ENABLED', res.isEnabled);
    },
  },
};
