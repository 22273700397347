/* eslint-disable consistent-return */
/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import axios from 'axios';
import Vue from 'vue';
import { NodesApiService, WorkloadsApiService } from '@/services/api';
import mqtt from '@/plugins/mqtt';
import NodeTreeModel, { TREE_NODE_TYPES } from '@/model/node-tree/node-tree.model';
import i18n from '@/i18n';
import store from '@/store';
import { useStoreDna } from '@/store/modules/pinia/dna';

class NodeTreeHelper {
  /**
   * @description Find node by passing a key or a collection and a key (dot notation)
   * to retrieve a node eg 'id' or 'device.id'
   * @param collection
   * @param key
   * @param valueOfKey
   * @returns {*}
   */
  // eslint-disable-next-line class-methods-use-this
  findRecursively(collection, key, valueOfKey) {
    const iter = (node) => {
      const keyArray = key.split('.');
      if (keyArray.length > 1) {
        if (node.data[keyArray[0]] && node.data[keyArray[0]][keyArray[1]] === valueOfKey) {
          // eslint-disable-next-line no-use-before-define
          foundNode = node;
          return true;
        }
      } else if (node.data[key] === valueOfKey) {
        // eslint-disable-next-line no-use-before-define
        foundNode = node;
        return true;
      }

      return node.children && node.children.some(iter);
    };

    let foundNode;
    collection.some(iter);
    return foundNode;
  }

  // eslint-disable-next-line class-methods-use-this
  searchNodesRecursively(collection, key, term) {
    const iter = (node) => {
      if (
        (key === 'serialNumber' && node.data && node.data.device && node.data.device.serialNumber === term) ||
        (node.data[key] && node.data[key].toLowerCase().includes(term.toLowerCase()))
      ) {
        // eslint-disable-next-line no-use-before-define
        foundNodes.push(node);
      }
      return node.children && node.children.forEach(iter);
    };
    const foundNodes = [];
    collection.forEach(iter);
    return foundNodes;
  }

  /**
   * Expand provided node,
   * if no parentId and is not root node
   * expand unassigned
   * @param stateNodes
   * @param node
   */
  expandParentsRecursively(stateNodes, node) {
    if (node.data.parentId) {
      const parentNode = this.findRecursively(stateNodes, 'id', node.data.parentId);
      parentNode.isExpanded = true;
      this.expandParentsRecursively(stateNodes, parentNode);
    } else if (!node.isTypeOf(TREE_NODE_TYPES.ROOT)) {
      const root = this.findRecursively(stateNodes, 'type', TREE_NODE_TYPES.ROOT);
      root.isExpanded = true;
      const unassignedNode = this.findRecursively(stateNodes, 'type', TREE_NODE_TYPES.UNASSIGNED);
      unassignedNode.isExpanded = true;
    }
  }

  /**
   * Highlight found node by changing isSelected
   * and canBeSelected flag for a second
   * @param node
   */
  // eslint-disable-next-line class-methods-use-this
  highlightFoundNode({ node, highlightWithoutTimeout }) {
    node.isSelected = true;
    node.data.canBeSelected = true;
    if (!highlightWithoutTimeout) {
      setTimeout(() => {
        node.isSelected = false;
        node.data.canBeSelected = node.isTypeOf(TREE_NODE_TYPES.NODE);
      }, 1000);
    }
  }

  handleFoundNodeBySearch({ stateNodes, node, highlightWithoutTimeout }) {
    this.expandParentsRecursively(stateNodes, node);
    this.highlightFoundNode({ node, highlightWithoutTimeout });
  }

  /**
   * @description Iterate over whole tree structure and invoke callback for each node
   * @param nodes
   * @param callback
   */
  traverseNodeTree(nodes, callback) {
    nodes.forEach((node) => {
      callback(node);
      if (node.children) {
        this.traverseNodeTree(node.children, callback);
      }
    });
  }

  /**
   * @description Delete node by id from the tree structure
   * @param nodes
   * @param deletedNodeId
   */
  deleteRecursively(nodes, deletedNodeId) {
    // eslint-disable-next-line array-callback-return
    nodes.some((node, index) => {
      if (node.data && node.data.id === deletedNodeId) {
        nodes.splice(index, 1);
        return true;
      }

      if (node.children) {
        this.deleteRecursively(node.children, deletedNodeId);
      }
    });
  }

  /**
   * @description Reset isSelected to match the libraries state
   * it is a hacky solution for mqtt event updates, that resets users selection
   * if an message arrives when the user is doing operations on the tree
   * @param nodes
   * @param selectedTreeNodes
   */
  setSelectedTreeNodes(nodes, selectedTreeNodes) {
    this.traverseNodeTree(nodes, (node) => {
      node.isSelected = false;
    });
    if (selectedTreeNodes.length) {
      selectedTreeNodes.forEach((n) => {
        const findRecursively = this.findRecursively(nodes, 'id', n.data.id);
        if (findRecursively) {
          findRecursively.isSelected = true;
        }
      });
    }
  }

  // field used to place exchange interval timer instance
  exchangeInterval;

  // exchange interval length
  EXCHANGE_INTERVAL_LENGTH = 10000;

  // field used to set selected node from tree
  selectedNode;

  // flag for checking if startEmittingUpdates event triggered properly
  isTriggerStartEmitting = false;

  /**
   * @description setting timer to command node to continue
   * triggering on every 10 seconds
   * @type {function(): (*)}
   */
  async invokeMqttEvents() {
    try {
      this.exchangeInterval = setInterval(() => {
        if (!this.selectedNode || !this.selectedNode.isOnline() || !this.isTriggerStartEmitting) {
          return;
        }
        NodesApiService.keepEmittingUpdates({
          dataId: 'system_stats',
          serialNumber: this.selectedNode.serialNumber,
        }).catch((e) => {
          Vue.prototype.$log.debug('NodesApiService.keepEmittingUpdates ', e.message);
          this.clearExchangeInterval();
          mqtt.unsubscribeFrom('node', this.selectedNode);
        });
      }, this.EXCHANGE_INTERVAL_LENGTH);
    } catch (e) {
      Vue.prototype.$log.debug('NodesApiService.invokeMqttEvents ', e.message);
    }
  }

  /**
   * @description sending request that will trigger node to start sending mqtt events
   * @type {function(): (*)}
   */
  // eslint-disable-next-line class-methods-use-this
  async startEmitting({ newSelectedDevice }) {
    try {
      if (!newSelectedDevice.isOnline()) {
        return;
      }

      const source = axios.CancelToken.source();
      store.dispatch('node-tree/set_cancel_token_start_sending', source);
      await NodesApiService.startEmittingUpdates({
        dataId: 'system_stats',
        serialNumber: newSelectedDevice.serialNumber,
        cancelToken: source.token,
      });
      this.isTriggerStartEmitting = true;
    } catch (e) {
      this.isTriggerStartEmitting = false;
      Vue.prototype.$log.debug('NodesApiService.startEmittingUpdates ', e.message);
    }
  }

  /**
   * @description Set selected node from tree
   * @type {function(): (*)}
   */
  setSelectedNode(node) {
    this.selectedNode = node;
  }

  /**
   * @description Set StartEmitting flag
   * @type {function(): (*)}
   */
  setStartEmittingFlag(value) {
    this.isTriggerStartEmitting = value;
  }

  /**
   * @description Function for canceling pending requests
   * @param token - cancelation token for axios request
   */

  // eslint-disable-next-line class-methods-use-this
  async cancelPendingRequests(token) {
    if (token.cancel) {
      await token.cancel(i18n.t('nodes.tree.cancelRequestMessage.nodeRequests'));
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async cancelPendingDnaStatusRequests(token) {
    if (token.cancel) {
      await token.cancel(i18n.t('nodes.tree.cancelRequestMessage.dnaStatus'));
    }
  }

  /**
   * @description If node is online, send requests to get additional information
   * about the selected node, cached data and details
   * @param selectedDevice - selected tree node's device
   * @param workloadControlPermission
   * @returns {Promise<(*&{devices: *})|*>}
   */
  // eslint-disable-next-line class-methods-use-this
  async fetchNodeDetails(selectedDevice, workloadControlPermission, dna) {
    let fetchedNode = null;
    let ip_address = '';
    try {
      const storeDna = useStoreDna();
      if (!selectedDevice.isOnline()) {
        if (dna?.getDnaStatus) {
          storeDna.fetchTargetConfigurationEmpty();
        }
        return selectedDevice;
      }

      let source = axios.CancelToken.source();
      store.dispatch('node-tree/set_cancel_token', source);
      fetchedNode = await NodesApiService.getNodeById(selectedDevice.id, { fetchFreshNodeData: true }, source.token);
      if (dna?.isDnaSupported) {
        storeDna.fetchTargetConfiguration(selectedDevice.serialNumber);
      }

      source = axios.CancelToken.source();
      store.dispatch('node-tree/set_cancel_token', source);
      const cachedData = await NodesApiService.getCachedData({
        dataId: 'wan_ip_address',
        serialNumber: selectedDevice.serialNumber,
        fromNodeIfCacheEmpty: true,
        cancelToken: source.token,
      });
      ip_address = cachedData.values;
      if (workloadControlPermission) {
        source = axios.CancelToken.source();
        store.dispatch('node-tree/set_cancel_token', source);
        const devices = await WorkloadsApiService.getAllDeployedWorkloads({
          serialNumber: selectedDevice.serialNumber,
          cancelToken: source.token,
        });
        return {
          ...selectedDevice,
          ...fetchedNode,
          ...ip_address,
          devices,
        };
      }
      return { ...selectedDevice, ...fetchedNode, ...ip_address };
    } catch (e) {
      Vue.prototype.$log.debug(e);
      return {
        ...selectedDevice,
        ...fetchedNode,
        ...ip_address,
        cancelOcurred: e.message === i18n.t('nodes.tree.cancelRequestMessage.nodeRequests'),
      };
    }
  }

  /**
   * @description Clear exchange interval timer
   */
  clearExchangeInterval() {
    clearInterval(this.exchangeInterval);
    this.exchangeInterval = undefined;
  }

  /**
   * @description After child node is dropped, update its properties,
   * orderIndex, parentId and isSelected
   * @param child - Child tree node to be updated
   * @param parentNode - Parent node of the updating child
   * @returns {NodeTreeModel}
   */
  // eslint-disable-next-line class-methods-use-this
  updateChildAfterDrop(child, parentNode) {
    // path is array that is provided by the tree library,
    // last element is correct orderIndex for the node
    child.data.orderIndex = child.path.pop();
    child.data.parentId = parentNode.data.id;
    child.isSelected = false; // de-select all dropped nodes
    return new NodeTreeModel(child);
  }

  /**
   * @description Add or update and merge with existing deployed workload
   * workload received trough mqtt or api endpoint
   * @param workloads
   * @param treeNodeDevice
   * @returns {*[]}
   */
  // eslint-disable-next-line class-methods-use-this
  addOrUpdateAndMergeDeployedWls(workloads, treeNodeDevice) {
    const addedWorkloads = [];
    workloads.forEach((wl) => {
      treeNodeDevice.devices.forEach((device) => {
        if (device.id === wl.deviceId) {
          // to avoid that id property from device which is deviceId
          // is overwritten by workload id which is workloadId
          delete wl.id;
          Object.assign(device, wl);
        }
      });
      if (!treeNodeDevice.devices.map((d) => d.deviceId).includes(wl.deviceId)) {
        addedWorkloads.push(wl);
      }
    });
    return [...treeNodeDevice.devices, ...addedWorkloads];
  }

  /**
   * @description Iterate trough nodes collection and set orderIndex
   * @param nodes - nodes to be iterated
   * @returns {*}
   */
  // eslint-disable-next-line class-methods-use-this
  setOrderIndexTo(nodes) {
    return nodes.map((n, index) => {
      n.orderIndex = index;
      return n;
    });
  }

  /**
   * @description determine if dragged nodes have common parent, no backup in that case
   * @param draggedNodes
   * @param parentNode
   * @returns {*}
   */
  // eslint-disable-next-line class-methods-use-this
  shouldBeBackuped(draggedNodes, parentNode) {
    return !draggedNodes.every((n) => n.data.parentId === parentNode.data.id);
  }
}

export default new NodeTreeHelper();
