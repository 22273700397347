/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

// import store from '@/store'
import { NotificationApiService } from '@/services/api';
import NotificationModel from '@/model/notification/notification.model';
import store from '@/store';

export default {
  state: {
    notifications: [],
    count: 0,
    notificationModel: new NotificationModel(),
    activeNotification: {},
    delete: false,
  },

  getters: {
    getNotificationData: (state) => state.notificationModel,
    list: (state) => state.notifications,
    count: (state) => state.count,
    getActiveNotification: (state) => state.activeNotification,
  },

  mutations: {
    SET_NOTIFICATIONS(state, notifications) {
      state.notifications = notifications.data.map((notification) => new NotificationModel(notification));
      state.count = notifications.count;
    },
    REMOVE_NOTIFICATION(state, id) {
      state.notifications.splice(
        state.notifications.findIndex((notification) => notification._id === id),
        1,
      );
    },
    SET_NOTIFICATION_DATA(state, notificationModel) {
      state.notificationModel = new NotificationModel(notificationModel);
    },
    SET_ACTIVE_NOTIFICATION(state, activeNotification) {
      state.activeNotification = activeNotification;
    },
  },

  actions: {
    async fetch({ state, commit }, parameters = {}) {
      if (state.delete && state.notifications.length === 0 && parameters.page > 1) {
        parameters.page -= 1;
      }
      const params = {
        filterBy: parameters.search ? { headerText: parameters.search } : {},
        limit: parameters.itemsPerPage || 10,
        page: parameters.page || 1,
      };
      commit('SET_NOTIFICATIONS', await NotificationApiService.fetchNotifications({ params }));
    },
    async remove({ state, commit }, { _id }) {
      state.delete = true;
      await NotificationApiService.remove(_id);
      commit('REMOVE_NOTIFICATION', _id);
      store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text: 'notifications.list.removeNotification',
        color: 'success',
        showClose: true,
      });
    },
    async get_notification({ commit }, id) {
      commit('SET_NOTIFICATION_DATA', await NotificationApiService.getNotification(id));
    },
    async reset_notification({ commit }) {
      commit('SET_NOTIFICATION_DATA', {});
    },
    async create_or_update_notification({ commit }, allData) {
      const { headerText, textMessage, active, showBeforeLogin, backgroundImage, _id } = allData.data;
      const formData = new FormData();
      formData.append('headerText', headerText);
      formData.append('textMessage', textMessage);
      formData.append('active', JSON.stringify(active));
      formData.append('showBeforeLogin', JSON.stringify(showBeforeLogin));
      if (backgroundImage) {
        formData.append('backgroundImage', backgroundImage);
      }
      if (allData.image) {
        formData.append('image', allData.image);
      }
      commit('SET_NOTIFICATION_DATA', await NotificationApiService.createOrUpdateNotification(formData, _id));
    },
    async getActiveNotificationAfterLogin({ commit }) {
      commit('SET_ACTIVE_NOTIFICATION', await NotificationApiService.getActiveNotificationAfterLogin());
    },
    async getActiveNotificationBeforeLogin({ commit }) {
      commit('SET_ACTIVE_NOTIFICATION', await NotificationApiService.getActiveNotificationBeforeLogin());
    },
    getImage({ state }) {
      const NOTIFICATION_IMAGES_PATH = '/nerve_notification/storage/background_images';
      const NO_AUTH_NOTIFICATION_IMAGES_PATH = '/nerve_notification/storage/background_images_public';
      if (state.activeNotification.showBeforeLogin) {
        return `${NO_AUTH_NOTIFICATION_IMAGES_PATH + state.activeNotification.backgroundImage}?${new Date().getTime()}`;
      }
      return `${NOTIFICATION_IMAGES_PATH + state.activeNotification.backgroundImage}?${new Date().getTime()}`;
    },
  },
};
