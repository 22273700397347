import Vue from 'vue';
import Vuetify from 'vuetify';

import { en, de, ko } from 'vuetify/es5/locale';
import Icon from '@/components/icons/Icon.vue';
import TreeFolderIcon from '@/components/icons/TreeFolderIcon.vue';
import TreeFolderIconCollapsed from '@/components/icons/TreeFolderIconCollapsed.vue';
import OfflineIcon from '@/components/icons/OfflineIcon.vue';
import OnlineIcon from '@/components/icons/OnlineIcon.vue';
import DockerIcon from '@/components/icons/DockerIcon.vue';
import VmIcon from '@/components/icons/VmIcon.vue';
import CodesysIcon from '@/components/icons/CodesysIcon.vue';
import EnableIcon from '@/components/icons/EnableIcon.vue';
import DisableIcon from '@/components/icons/DisableIcon.vue';
import theme from './theme';

Vue.use(Vuetify);

const opts = {
  theme,
  lang: {
    locales: { en, de, ko },
    current: 'en',
  },
  icons: {
    iconfont: 'mdiSvg',
    values: {
      menu: {
        component: Icon,
        props: {
          iconName: 'icon-menu',
        },
      },
      nodes: {
        component: Icon,
        props: {
          iconName: 'icon-nodes',
        },
      },
      workloads: {
        component: Icon,
        props: {
          iconName: 'icon-workloads',
        },
      },
      enable: {
        component: EnableIcon,
      },
      disable: {
        component: DisableIcon,
      },
      deploy: {
        component: Icon,
        props: {
          iconName: 'icon-deploy',
        },
      },
      labels: {
        component: Icon,
        props: {
          iconName: 'icon-labels',
        },
      },
      access: {
        component: Icon,
        props: {
          iconName: 'icon-users',
        },
      },
      remotes: {
        component: Icon,
        props: {
          iconName: 'icon-remotes',
        },
      },
      iconCod: {
        component: CodesysIcon,
      },
      iconDoc: {
        component: DockerIcon,
      },
      iconVm: {
        component: VmIcon,
      },
      notifications: {
        component: Icon,
        props: {
          iconName: 'icon-notifications',
        },
      },
      networkConfig: {
        component: Icon,
        props: {
          iconName: 'icon-network-config',
        },
      },
      'lang-en': {
        component: Icon,
        props: {
          iconName: 'icon-lang-en',
        },
      },
      'lang-de': {
        component: Icon,
        props: {
          iconName: 'icon-lang-de',
        },
      },
      'lang-ko': {
        component: Icon,
        props: {
          iconName: 'icon-lang-ko',
        },
      },
      logout: {
        component: Icon,
        props: {
          iconName: 'icon-logout',
        },
      },
      menuClose: {
        component: Icon,
        props: {
          iconName: 'icon-close',
        },
      },
      codesysPage: {
        component: Icon,
        props: {
          iconName: 'codesys-page',
        },
      },
      dockerPage: {
        component: Icon,
        props: {
          iconName: 'docker',
        },
      },
      codesysManageIcon: {
        component: Icon,
        props: {
          iconName: 'codesys-manage-icon',
        },
      },
      dockerManageIcon: {
        component: Icon,
        props: {
          iconName: 'docker-manage-icon',
        },
      },
      vmManageIcon: {
        component: Icon,
        props: {
          iconName: 'vm-manage-icon',
        },
      },
      deployWorkload: {
        component: Icon,
        props: {
          iconName: 'deploy',
        },
      },
      remoteConnect: {
        component: Icon,
        props: {
          iconName: 'remote-connect',
        },
      },
      offline: {
        component: OfflineIcon,
      },
      online: {
        component: OnlineIcon,
      },
      treeFolderCollapsed: {
        component: TreeFolderIconCollapsed,
      },
      treeFolder: {
        component: TreeFolderIcon,
      },
      nodeTreeBlue: {
        component: () => import('@/components/icons/NodeTreeBlueIcon.vue'),
      },
      nodeTree: {
        component: () => import('@/components/icons/NodeTreeIcon.vue'),
      },
      docker: {
        component: () => import('@/components/icons/DockerIcon.vue'),
      },
      codesys: {
        component: () => import('@/components/icons/CodesysIcon.vue'),
      },
      vm: {
        component: () => import('@/components/icons/VmIcon.vue'),
      },
      compose: {
        component: () => import('@/components/icons/DockerComposeIcon.vue'),
      },
      systemUpdate: {
        component: Icon,
        props: {
          iconName: 'system-update',
        },
      },
      systemUpdateBlue: {
        component: Icon,
        props: {
          iconName: 'system-update-blue',
        },
      },
      report: {
        component: Icon,
        props: {
          iconName: 'report',
        },
      },
      reportBlue: {
        component: Icon,
        props: {
          iconName: 'report-blue',
        },
      },
      update: {
        component: Icon,
        props: {
          iconName: 'update',
        },
      },
      deployIcon: {
        component: Icon,
        props: {
          iconName: 'deploy_icon',
        },
      },
    },
  },
};

export default new Vuetify(opts);
