/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import { v1 as uuidv1 } from 'uuid';
import ConnectionTunnel from '@/model/node/connectionTunnel.model';
import ConnectionScreen from '@/model/node/connectionScreen.model';
import RemoteConnectionModel from '@/model/node/remoteConnection.model';
import { RemoteConnectionApiService, WorkloadsApiService } from '@/services/api';
import store from '@/store';
import { TYPE_OF_WORKLOADS } from '../../constants';

export default {
  state: {
    remoteConnectionList: [],
    rcTunnelDialog: false,
    rcScreenDialog: false,
    rcTunnel: {},
    rcScreen: {},
    edit: {},
    isOpenCloseInitConnectionDialog: false,
    isOpenCloseRemoteConnectionsWorkloadDialog: false,
    isOpenConnectingDialog: false,
    reqId: '',
    connected: false,
    activeConnRequestUid: null,
    connectionType: {},
    rc: {},
    wlRc: {},
    workloadType: '',
    selectedNodeId: '',
    selectedWorkloadId: '',
    listOfServices: [],
  },

  mutations: {
    SET_REMOTE_LIST(state, rc) {
      state.remoteConnectionList = rc.map((r) => new RemoteConnectionModel({ ...r }));
    },
    ADD_UPDATE_REMOTE_CONNECTION(state, rc) {
      // rc already exist -> edit the existing rc
      if (rc.index !== undefined) {
        state.remoteConnectionList.splice(rc.index, 1, new RemoteConnectionModel(rc));
        return;
      }
      // create a new rc
      state.remoteConnectionList.push(new RemoteConnectionModel(rc));
    },
    DELETE_REMOTE_LIST_WORKLOAD(state, { index }) {
      state.remoteConnectionList.splice(index, 1);
    },
    SET_TUNNEL_RC_DATA(state, { index }) {
      const rcTunnel = state.remoteConnectionList[index];
      state.rcTunnel = new ConnectionTunnel(rcTunnel);
      state.rcTunnel.oldName = state.rcTunnel.name;
      state.rcTunnel.index = index;
    },
    SET_SCREEN_RC__DATA(state, { index }) {
      const rcScreen = state.remoteConnectionList[index];
      state.rcScreen = new ConnectionScreen(rcScreen);
      state.rcScreen.oldName = state.rcScreen.name;
      state.rcScreen.index = index;
    },
    SET_RC_TUNNEL_DIALOG(state, stateDialog) {
      state.rcTunnelDialog = stateDialog;
    },
    SET_RC_SCREEN_DIALOG(state, stateDialog) {
      state.rcScreenDialog = stateDialog;
    },

    /**
     * remoteConnectionDialog.vue
     */
    OPEN_CLOSE_INIT_CONNECTION_DIALOG(state, value) {
      state.isOpenCloseInitConnectionDialog = value;
    },

    OPEN_CLOSE_REMOTE_CONNECTIONS_WL_DIALOG(state, value) {
      state.isOpenCloseRemoteConnectionsWorkloadDialog = value;
    },

    /**
     * dialogForEstablishConnection.vue
     */
    OPEN_CLOSE_CONNECTING_DIALOG(state, value) {
      state.isOpenConnectingDialog = value;
    },
    SET_CONNECTED(state, connected) {
      state.connected = connected;
    },
    SET_ACTIVE_CONN_REQUEST_UID(state, requestUid) {
      state.activeConnRequestUid = requestUid;
    },
    CONNECTION_TYPE(state, connectionType) {
      if (connectionType.type) {
        state.connectionType = connectionType;
      }
    },
    SET_RC(state, rc) {
      state.rc = rc;
    },

    SET_WL_RC(state, rc) {
      state.wlRc = rc;
    },
    SET_WORKLOAD_TYPE(state, workloadType) {
      state.workloadType = workloadType;
    },
    SET_SELECTED_NODE_OR_WL_ID(state, { type, selectedId }) {
      if (type === 'workload') {
        state.selectedWorkloadId = selectedId;
        return;
      }
      state.selectedNodeId = selectedId;
    },
    RESET_REMOTE_LIST(state) {
      state.remoteConnectionList = [];
    },
    SET_LIST_OF_SERVICES(state, listOfServices) {
      state.listOfServices = listOfServices;
    },
  },
  getters: {
    list: (state) => state.remoteConnectionList,
    rcTunnelDialog: (state) => state.rcTunnelDialog,
    getTunnelRc: (state) => state.rcTunnel,
    getScreenRc: (state) => state.rcScreen,
    rcScreenDialog: (state) => state.rcScreenDialog,
    rcScreen: (state) => state.rcScreen,
    isOpenCloseInitConnectionDialog: (state) => state.isOpenCloseInitConnectionDialog,
    // eslint-disable-next-line max-len
    isOpenCloseRemoteConnectionsWorkloadDialog: (state) => state.isOpenCloseRemoteConnectionsWorkloadDialog,
    isOpenConnectingDialog: (state) => state.isOpenConnectingDialog,
    connected: (state) => state.connected,
    activeConnRequestUid: (state) => state.activeConnRequestUid,
    connectionType: (state) => state.connectionType,
    rc: (state) => state.rc,
    getWlRc: (state) => state.wlRc,
    getWorkloadType: (state) => state.workloadType,
    getSelectedWorkloadId: (state) => state.selectedWorkloadId,
    getSelectedNodeId: (state) => state.selectedNodeId,
    getListOfServices: (state) => state.listOfServices,
  },
  actions: {
    async fetch({ commit }, data) {
      if (data && data.remoteConnections) {
        if (data.remoteConnections.length) {
          commit('SET_REMOTE_LIST', data.remoteConnections);
          return;
        }
        commit('SET_REMOTE_LIST', []);
      }
    },
    async update_node({ commit }, { nodeData }) {
      if (nodeData.delete) {
        commit('DELETE_REMOTE_LIST_WORKLOAD', { index: nodeData.index });
        return;
      }
      commit('ADD_UPDATE_REMOTE_CONNECTION', nodeData.connection);
    },
    async add_update_remote_connection({ commit }, { connection }) {
      // add new rc during creation of wl version
      commit('ADD_UPDATE_REMOTE_CONNECTION', connection);
    },
    async delete_remote_connection({ commit }, params) {
      commit('DELETE_REMOTE_LIST_WORKLOAD', { index: params.index });
    },
    openCloseInitConnectionDialog({ commit }, value) {
      commit('OPEN_CLOSE_INIT_CONNECTION_DIALOG', value);
    },
    openCloseRemoteConnectionsWorkloadDialog({ commit }, value) {
      commit('OPEN_CLOSE_REMOTE_CONNECTIONS_WL_DIALOG', value);
    },
    async openCloseConnectingDialog({ commit }, value) {
      commit('OPEN_CLOSE_CONNECTING_DIALOG', value);
    },
    get_tunnel_rc({ commit }, params) {
      commit('SET_TUNNEL_RC_DATA', params);
    },
    get_screen_rc({ commit }, params) {
      commit('SET_SCREEN_RC__DATA', params);
    },
    set_rc_tunnel_dialog({ commit }, stateDialog) {
      commit('SET_RC_TUNNEL_DIALOG', stateDialog);
    },
    set_rc_screen_dialog({ commit }, stateDialog) {
      commit('SET_RC_SCREEN_DIALOG', stateDialog);
    },

    /**
     * Send an API request to BE in order to establish a connection
     */
    async remoteConnect({ commit, state }, remoteConnection) {
      // Generate unique id of connection request(NERVESW-9868)
      remoteConnection.uniqueConnectionRequestNo = uuidv1();

      commit('SET_RC', remoteConnection);

      // Remember the connectionRequestUID in the store
      // It is used to match mqtt message which arrives from BE
      state.reqId = remoteConnection.uniqueConnectionRequestNo;

      const payload = await RemoteConnectionApiService.remoteConnect(remoteConnection);

      if (payload && payload.error) {
        // Close connecting dialog(dialog with a spinner and message Waiting...)
        commit('OPEN_CLOSE_CONNECTING_DIALOG', false);

        // temp solution to display specific error message instead of generic one
        if (payload.errVersionMismatch) {
          await store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'remoteConnection.managementToastFailedVersionMismatch',
            color: 'red',
            showClose: true,
          });
          return;
        }

        if (payload.error.code) {
          await store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: payload.error.code,
            color: 'red',
            showClose: true,
          });
          return;
        }
        // A generic error message
        await store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'remoteConnection.managementToastFailed',
          color: 'red',
          showClose: true,
        });
      }

      /*
      CONNECTED is not a part of the API response when establishing a connection for the first time
      */
      if (typeof payload.connected === 'boolean') {
        commit('SET_CONNECTED', payload.connected);
      }
      commit('SET_ACTIVE_CONN_REQUEST_UID', payload.requestUid);

      if (!payload.connected) {
        return;
      }
      commit('CONNECTION_TYPE', payload);
    },

    async cancelRemoteConnApproval({ commit }, activeConnRequestUid) {
      commit('SET_ACTIVE_CONN_REQUEST_UID', null);
      await RemoteConnectionApiService.cancelRemoteConnApproval(activeConnRequestUid);
    },

    async mqtt_rc({ commit, state }, payload) {
      if (state.reqId !== payload.requestId) {
        return;
      }

      // If the mqtt message is received before the API request is completed
      // Store connection request UID
      commit('SET_ACTIVE_CONN_REQUEST_UID', payload.requestId);

      if (payload.status === 'declined') {
        commit('OPEN_CLOSE_CONNECTING_DIALOG', false);
        await store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'remoteConnection.guacamoleClientMsnConnectionRejected',
          color: 'red',
          showClose: true,
        });
      }

      if (payload.status === 'disconnected') {
        commit('OPEN_CLOSE_CONNECTING_DIALOG', false);
        await store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'remoteConnection.guacamoleClientMsnConnectionDisconnected',
          color: 'red',
          showClose: true,
        });
      }

      if (payload.status === 'failed') {
        commit('OPEN_CLOSE_CONNECTING_DIALOG', false);
        if (payload.error.code) {
          await store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: payload.error.code,
            color: 'red',
            showClose: true,
          });
          return;
        }

        await store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: payload.error,
          color: 'red',
          showClose: true,
        });
      }

      if (payload.status === 'connected') {
        if (payload.type === 'TUNNEL') {
          commit('SET_CONNECTED', true);
        }
        commit('CONNECTION_TYPE', payload);
      }
    },

    async guacamoleConnectionTab(_, data) {
      await RemoteConnectionApiService.guacamoleConnectionTab(data);
    },

    async getWorkloadVersionRc({ commit }, data) {
      const workload = await WorkloadsApiService.getWorkloadVersionRc(data);
      commit('SET_WL_RC', workload.versions && workload.versions[0] && workload.versions[0].remoteConnections);
      // If workload is compose, set list of services.
      // This is needed to match if service defined in remote connection is defined in compose workload
      if (workload.type === TYPE_OF_WORKLOADS.COMPOSE) {
        workload?.versions[0]?.files.forEach((file) => {
          if (file.sourceInfo.type === 'compose') {
            const fileContent = JSON.parse(file.sourceInfo.content);
            if (fileContent && fileContent.services) {
              commit('SET_LIST_OF_SERVICES', Object.getOwnPropertyNames(fileContent.services));
            }
          }
        });
      }
    },

    set_connected({ commit }, value) {
      commit('SET_CONNECTED', value);
    },

    set_workload_type({ commit }, workloadType) {
      commit('SET_WORKLOAD_TYPE', workloadType);
    },
    clear_connection_type({ commit }) {
      commit('CONNECTION_TYPE', {});
    },
    set_selected_node_or_wl_id({ commit }, payload) {
      commit('SET_SELECTED_NODE_OR_WL_ID', payload);
    },
    async import_remote_connections({ commit }, params) {
      const rc = await RemoteConnectionApiService.importRemoteConnections(params);
      commit('SET_REMOTE_LIST', rc);
      if (params.isWorkload) {
        store.dispatch('workloads/removeRc');
        rc.forEach((connection) => {
          this.dispatch('workloads/add_edit_rc_from_workload', { connection });
        });
      }
    },
    clear_remote_connection_list_state({ commit }) {
      commit('RESET_REMOTE_LIST');
    },
  },
};
