<!-- eslint-disable vue/no-v-html -->
<!-- The HTML is generated locally -->
<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2022. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->
<template>
  <nerve-dialog
    id="iiotNotificationDialog"
    v-model="dialogState"
    scrollable
    persistent
    max-width="30vw"
    data-cy="iiotNotificationDialog"
    @keydown.esc="close()"
    @keydown.enter="confirm()"
  >
    <v-card
      class="backgroundImage notification-dialog"
      :style="{ backgroundImage: 'url(' + dialogParams.backgroundImage + ')' }"
    >
      <v-card-title id="iiotNotificationDialogTitle" data-cy="iiotNotificationDialogTitle">
        <div>
          <span class="pl-1 pr-1 introduction">
            {{ dialogParams.title }}
          </span>
        </div>
      </v-card-title>
      <v-card-text
        v-if="dialogParams.textMessageWithLinks"
        id="iiotNotificationDialogSubtitle"
        data-cy="iiotNotificationDialogSubtitle"
      >
        <mark class="pl-1 pr-1 introduction">
          <span class="ma-2" v-html="dialogParams.textMessageWithLinks" />
        </mark>
      </v-card-text>
      <v-card-text v-else>
        {{ dialogParams.subTitle }}
      </v-card-text>
      <v-card-actions>
        <nerve-button
          id="iiotNotificationDialogButtonOk"
          :text="$t('errorDialog.okBtn')"
          type-of-btn="action"
          size="normal"
          data-cy="iiotNotificationDialogButtonOk"
          autofocus
          @click-event="confirm()"
        />
      </v-card-actions>
    </v-card>
  </nerve-dialog>
</template>

<script>
import { NerveButton, NerveDialog } from 'nerve-ui-components';

export default {
  name: 'InfoDialog',
  components: { NerveButton, NerveDialog },
  computed: {
    dialogState: {
      get() {
        return this.$store.getters['utils/_api_request_handler/getNotificationDialogState']();
      },
      set() {
        this.close();
      },
    },
    dialogParams() {
      return this.$store.getters['utils/_api_request_handler/getNotificationDialogParams']();
    },
  },
  methods: {
    async confirm() {
      if (this.dialogParams.cancelCallback) {
        await this.dialogParams.cancelCallback();
      }
      this.$store.dispatch('utils/_api_request_handler/close_notification');
    },
    async handler() {
      this.close();
    },
  },
};
</script>

<style scoped>
.backgroundImage {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.notification-dialog {
  max-height: 460px !important;
  min-height: 320px !important;
}

.introduction {
  background-color: #ffffff;
  border-radius: 6px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  opacity: 0.8;
  line-height: 1.3em;
}
</style>
