<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-toolbar id="iiotToolbarCoreToolbar" v-resize="onResize" data-cy="iiotToolbarCoreToolbar">
    <div class="menu">
      <v-btn id="iiotToolbarDrawer" data-cy="iiotToolbarDrawer" icon @click="openDrawer">
        <v-icon v-if="openedMenu">
          {{ '$vuetify.icons.menu' }}
        </v-icon>
        <v-icon v-else-if="!openedMenu">
          {{ '$vuetify.icons.menuClose' }}
        </v-icon>
      </v-btn>
    </div>
    <v-btn v-show="isLogoVisible" v-resize="isNerveLogoVisible" class="logo" icon to="/nodes">
      <v-img :src="require('@/assets/img/nerve.svg')" />
    </v-btn>
    <v-spacer />
    <div class="d-flex">
      <v-layout align-center justify-end>
        <v-layout id="iiotToolbarLangs" data-cy="iiotToolbarLangs" align-center class="mr-2 mt-4 ml-2">
          <v-select id="iiotToolbarLanguageSelect" v-model="$root.$i18n.locale" :items="languages" dark>
            <template #selection="{ item }">
              <v-icon :id="'lang-icon-selection-' + item" :data-cy="'lang-icon-selection-' + item">
                {{ '$vuetify.icons.lang-' + item }}
              </v-icon>
            </template>
            <template #item="{ item }">
              <v-icon :id="'lang-icon-item-' + item" :data-cy="'lang-icon-item-' + item">
                {{ '$vuetify.icons.lang-' + item }}
              </v-icon>
            </template>
          </v-select>
        </v-layout>
        <v-layout v-if="isServiceUnavailable.isUnavailable" class="pl-3">
          <v-tooltip left>
            <template #activator="{ on, attrs }">
              <v-icon id="iiotToolbarWarningIcon" color="red" v-bind="attrs" class="pl-3 pr-1" v-on="on">
                warning
              </v-icon>
            </template>
            <span>{{ $t('errorMessages.svc_0004') }}{{ isServiceUnavailable.service }}</span>
          </v-tooltip>
        </v-layout>
        <div class="avatar-space pl-9">
          <v-layout justify-space-between align-center>
            <div id="iiotToolbarAvatar" class="nerve-avatar" data-cy="iiotToolbarAvatar">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-avatar :class="{ 'mr-4': isMarginVisible }" v-on="on">
                    <span v-if="authUser.profileImgURL === '' || authUser.profileImgURL === null">{{ avatar }}</span>
                    <img v-else :src="getAvatarURL(authUser.profileImgURL)" />
                  </v-avatar>
                </template>
                <span>{{ authUser.firstName }} {{ authUser.lastName }}</span>
              </v-tooltip>
            </div>
            <div v-show="isUsernameVisible" v-resize="isUsernameOptionVisible">
              <v-toolbar-title> {{ authUser.firstName }} {{ authUser.lastName }}</v-toolbar-title>
            </div>
          </v-layout>
        </div>
        <div>
          <v-layout align-center justify-space-between>
            <div class="change-pass mr-3">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn
                    id="iiotToolbarUserSetting"
                    :ripple="false"
                    icon
                    :class="{ 'ml-4 mr-4': isMarginVisible }"
                    :disabled="!canAccess('UI_USER_SETTINGS:VIEW')"
                    data-cy="iiotToolbarUserSetting"
                    v-on="on"
                    @click="editUser()"
                  >
                    <v-icon>mdi-account</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('users.userSettings') }}</span>
              </v-tooltip>
            </div>
            <div v-show="isDocumentationVisible" v-resize="isDocumentationOptionVisible" class="change-pass">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn
                    id="iiotToolbarDocumentation"
                    :ripple="false"
                    icon
                    class="mr-8"
                    data-cy="iiotToolbarDocumentation"
                    href="https://docs.nerve.cloud/"
                    target="_blank"
                    v-on="on"
                  >
                    <v-icon>help</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('toolbar.documentation') }}</span>
              </v-tooltip>
            </div>
            <div class="logout ml-2">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn id="iiotLogout" data-cy="iiotLogout" icon v-on="on" @click="logout">
                    <v-icon>
                      {{ '$vuetify.icons.logout' }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('toolbar.logout') }}</span>
              </v-tooltip>
            </div>
          </v-layout>
        </div>
      </v-layout>
    </div>
  </v-toolbar>
</template>

<script>
import { mapMutations } from 'vuex';
import langs from '@/lang';
import AvatarMixin from '@/mixins/avatar.mixin';

export default {
  mixins: [AvatarMixin],
  data() {
    return {
      title: null,
      responsive: false,
      responsiveInput: false,
      toggleBtnDisabled: false,
      switchKey: 0,
      languages: Object.keys(langs),
      isMarginVisible: false,
      isLogoVisible: false,
      isDocumentationVisible: false,
      isUsernameVisible: false,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    authUser() {
      return this.$store.getters['auth/authUser'];
    },
    avatar() {
      // eslint-disable-next-line no-restricted-globals
      return this.authUser.firstName[0] + this.authUser.lastName[0];
    },
    openedMenu() {
      return this.$store.state.app.drawer;
    },
    isServiceUnavailable() {
      return this.$store.getters['auth/isServiceUnavailable'];
    },
  },
  watch: {
    $route(val) {
      this.title = val.name;
    },
    // eslint-disable-next-line func-names
    '$root.$i18n.locale': function (val) {
      localStorage.setItem('lang', val);
    },
  },
  async mounted() {
    this.onResponsiveInverted();

    window.addEventListener('resize', this.onResponsiveInverted);
    if (this.isLicense) {
      try {
        await this.$store.dispatch('network-state/get_network_state');
      } catch (err) {
        this.toggleBtnDisabled = true;
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResponsiveInverted);
  },

  methods: {
    ...mapMutations('app', ['SET_DRAWER', 'TOGGLE_DRAWER']),
    /**
     * Force v-switch component to re-render
     */
    forceSwitchRerender() {
      // eslint-disable-next-line no-plusplus
      this.switchKey++;
    },
    openDrawer() {
      if (this.$store.state.app.drawer) {
        this.SET_DRAWER(false);
      } else {
        this.SET_DRAWER(true);
      }
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
        this.responsiveInput = false;
      } else {
        this.responsive = false;
        this.responsiveInput = true;
      }
    },
    editUser() {
      this.$router.push({ name: 'User Settings' });
    },
    async logout() {
      try {
        await this.$store.dispatch('auth/logout');
      } catch (e) {
        this.$log.debug(e);
      }
    },
    onResize() {
      this.isMarginVisible = window.innerWidth > 960;
    },
    isNerveLogoVisible() {
      this.isLogoVisible = window.innerWidth > 800;
    },
    isDocumentationOptionVisible() {
      this.isDocumentationVisible = window.innerWidth > 375;
    },
    isUsernameOptionVisible() {
      this.isUsernameVisible = window.innerWidth > 860;
    },
  },
};
</script>

<style lang="scss">
#iiotToolbarCoreToolbar a {
  text-decoration: none;
}
.v-toolbar {
  min-height: 60px !important;
  border-radius: 0 !important;
  margin-bottom: 0 !important;
  height: 60px !important;
  flex: none !important;
}

.v-toolbar__content {
  height: 60px !important;
  min-height: 60px !important;
  background: var(--v-tertiary-base);
  margin: 0 !important;
  padding: 16px 30px 16px 27px !important;
}

.logo .v-btn__content .v-image .v-responsive__content {
  width: 100px !important;
}
.menu {
  margin-right: 38px;
}
.change-pass > .v-btn--active:before,
.v-btn:focus:before,
.v-btn:hover:before {
  opacity: 0 !important;
}
.menu > .v-btn.v-btn--icon {
  height: 28px !important;
  width: 28px !important;
}
.menu {
  svg {
    height: 28px !important;
    width: 28px !important;
  }
}

.nerve-avatar {
  color: var(--v-secondary-base);
  margin-right: 13px;
}

.nerve-avatar > .v-avatar {
  width: 40px;
  height: 40px;
  border-radius: 36px;
  border: solid 1.5px white;
}

.v-avatar > span {
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
}

.v-toolbar__content {
  color: var(--v-secondary-base) !important;
  font-size: 14px !important;
  font-weight: 500;
}

.v-toolbar__title {
  color: var(--v-secondary-base) !important;
  font-size: 14px !important;
  font-weight: 500;
}

.logout .v-btn.v-btn--icon {
  height: 30px !important;
  width: 30px !important;
}

.change-pass {
  .v-btn--outlined .v-btn__content .v-icon,
  .v-btn--round .v-btn__content .v-icon {
    color: var(--v-secondary-base);
  }
}

.change-pass > .v-tooltip > span {
  color: var(--v-secondary-base);
  background: var(--v-secondary-base);
}
.toggleBtn {
  margin-top: 20px;
}

.toolbar-titles {
  color: var(--v-primary-base) !important;
  padding-right: 3px;
}
// uncomment this below for language selection feature
#iiotToolbarLangs {
  max-width: 64px;
}

#lang-icon-selection {
  width: 32px;
}

#lang-icon-item {
  width: 64px;
}
</style>
