<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-navigation-drawer
    id="iiotAppDrawer"
    :mini-variant="isDrawerOpen"
    :mini-variant-width="miniVariantWidth"
    :permanent="isDrawerOpen"
    mobile-breakpoint="991"
    width="227"
    app
  >
    <div id="iiotDrawerNavigationList" class="side">
      <v-layout class="fill-height" tag="v-list" column justify-space-between>
        <div v-for="(link, i) in links" :key="i">
          <v-list-item
            v-if="!link.subLinks && link.isVisible"
            :id="link.id ? link.id : link.to ? link.to.substr(1).replaceAll('/', '_') : 'access'"
            :key="i"
            :data-cy="link.to ? link.to.substr(1).replaceAll('/', '_') : 'access'"
            :to="link.to"
            :active-class="`v-list-item--active ${link.activeClass}`"
            :class="`v-list-item ${link.class}`"
            :target="`${link.target}`"
            :style="isDrawerOpen ? 'width:80px !important' : 'width:225px !important'"
          >
            <template #default="{ active }">
              <v-list-item-action :class="{ 'margin-right-25': isDrawerOpen }" class="icon-position">
                <v-img id="iiotDrawerIcon" :src="`/img/${active ? link.srcSelected : link.src}`" />
              </v-list-item-action>
              <v-list-item-title v-if="!isDrawerOpen">
                {{ link.text }}
              </v-list-item-title>
            </template>
          </v-list-item>
          <v-list-group
            v-if="link.subLinks && link.isVisible"
            :id="link.to ? link.to.substr(1).replaceAll('/', '_') : 'access'"
            :data-cy="link.to ? link.to.substr(1).replaceAll('/', '_') : 'access'"
            :prepend-icon="''"
            :no-action="true"
            :active-class="'active-class'"
            :class="link.to === $route.path ? 'active-class' : ''"
            sub-group
          >
            <template #appendIcon>
              <v-icon v-if="link.isExistsSubElement" size="30"> mdi-menu-right </v-icon>
            </template>
            <template #activator>
              <v-list-item
                v-slot="{ active }"
                class="spreadItem"
                :to="link.to"
                :style="isDrawerOpen ? 'width:80px !important' : 'width:225px !important'"
              >
                <v-list-item-action :class="{ 'margin-right-25': isDrawerOpen }">
                  <v-img id="iiotDrawerIconSub" :src="`/img/${active ? link.srcSelected : link.src}`" />
                </v-list-item-action>
                <v-list-item-title v-if="!isDrawerOpen">
                  {{ link.text }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <div v-for="sublink in link.subLinks" :key="sublink.text">
              <v-list-item
                v-if="sublink.isVisible"
                v-show="!isDrawerOpen"
                :id="sublink.to.substr(1).replaceAll('/', '_')"
                :key="sublink.text"
                :data-cy="sublink.to.substr(1).replaceAll('/', '_')"
                :to="sublink.to"
              >
                <v-list-item-action :class="{ 'margin-right-25': isDrawerOpen }" class="icon-position">
                  <v-icon>
                    {{ sublink.icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-title>
                  {{ sublink.text }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-list-group>
        </div>
      </v-layout>
    </div>
    <div class="version">
      <div
        v-if="
          canAccess('UI_USAGE_REPORT:VIEW') ||
          canAccess('VERSION:LIST') ||
          canAccess('UI_SERVER_AUDIT:VIEW') ||
          canAccess('UI_SERVER_LOGS:VIEW')
        "
      >
        <v-list-item>
          <div v-if="!isDrawerOpen">
            <div v-if="!isUpdateAvailable">
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    id="iiotDrawerSystemInfoBtn"
                    dark
                    text
                    data-cy="iiotDrawerSystemInfoBtn"
                    v-on="on"
                    @click="goToSystemInfo()"
                  >
                    {{ $t('drawer.systemInfo').toUpperCase() }}
                  </v-btn>
                </template>
                <span>{{ version.currentVersion }}</span>
              </v-tooltip>
            </div>
            <div v-else>
              <v-row no-gutters>
                <v-col cols="9">
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        id="iiotDrawerSystemInfoBtn"
                        dark
                        text
                        data-cy="iiotDrawerSystemInfoBtn"
                        v-on="on"
                        @click="goToSystemInfo()"
                      >
                        {{ $t('drawer.systemInfo').toUpperCase() }}
                      </v-btn>
                    </template>
                    <span>{{ version.currentVersion }}</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="1">
                  <div v-if="canAccess('VERSION:LIST')">
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" @click="goToSystemInfo()">
                          <v-icon id="iiotDrawerSystemUpdateIcon" data-cy="iiotDrawerSystemUpdateIcon">
                            {{ '$vuetify.icons.systemUpdateBlue' }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('drawer.updateAvailable') }}</span>
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
          <div v-else>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  id="iiotDrawerSystemInfoBtn"
                  dark
                  text
                  data-cy="iiotDrawerSystemInfoBtn"
                  v-on="on"
                  @click="goToSystemInfo()"
                >
                  {{
                    version.currentVersion.length > 7
                      ? version.currentVersion.substring(0, 6) + '...'
                      : version.currentVersion
                  }}
                </v-btn>
              </template>
              <span>{{ version.currentVersion }}</span>
            </v-tooltip>
          </div>
        </v-list-item>
      </div>
      <div v-else>
        <v-list-item>
          <span v-if="!isDrawerOpen" id="iiotDrawerSystemInfoSpanOpen" data-cy="iiotDrawerSystemInfoSpanOpen">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <span class="system-info-span">
                    {{ $t('drawer.systemInfo').toUpperCase() }}
                  </span>
                </div>
              </template>
              <span>
                {{ version.currentVersion }}
              </span>
            </v-tooltip>
          </span>
          <span v-else id="iiotDrawerSystemInfoSpanClose" data-cy="iiotDrawerSystemInfoSpanClose">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <span class="system-info-span">
                    {{
                      version.currentVersion.length > 7
                        ? version.currentVersion.substring(0, 6) + '...'
                        : version.currentVersion
                    }}
                  </span>
                </div>
              </template>
              <span>
                {{ version.currentVersion }}
              </span>
            </v-tooltip>
          </span>
        </v-list-item>
      </div>
    </div>
  </v-navigation-drawer>
</template>
<script>
// Utilities
import { mapMutations } from 'vuex';
import shared from '../helpers/shared';

export default {
  data: () => ({
    logo: './img/TTTech.png',
    responsive: false,
    miniVariantWidth: 227,
    isUpdateAvailable: false,
  }),
  computed: {
    version() {
      return this.$store.getters['version/getCurrentVersion']();
    },
    getAvailableVersions() {
      return this.$store.getters['version/getAvailableVersions']();
    },
    isDrawerOpen: {
      get() {
        return this.$store.state.app.drawer;
      },
    },
    sidebarOverlayGradient() {
      return `${this.$store.state.app.sidebarBackgroundColor}, ${this.$store.state.app.sidebarBackgroundColor}`;
    },
    links() {
      return [
        {
          to: '/nodes',
          src: 'NodesDrawer.svg',
          srcSelected: 'NodesSelectedDrawer.svg',
          text: this.$t('drawer.links.nodes'),
          isExistsSubElement:
            this.canAccess('UI_SUBNAV_NODE_UPDATE:VIEW') || this.canAccess('UI_SUBNAV_NODE_UPDATE_LOG:VIEW'),
          isVisible: this.canAccess('UI_NAV_NODES:VIEW'),
          subLinks: [
            {
              to: '/node/update/list',
              text: this.$t('drawer.links.update'),
              isVisible: this.canAccess('UI_SUBNAV_NODE_UPDATE:VIEW'),
            },
            {
              to: '/node/update/log',
              text: this.$t('drawer.links.updateLog'),
              isVisible: this.canAccess('UI_SUBNAV_NODE_UPDATE_LOG:VIEW'),
            },
          ],
        },
        {
          to: '/workloads',
          src: 'WorkloadsDrawer.svg',
          srcSelected: 'WorkloadsSelectedDrawer.svg',
          text: this.$t('drawer.links.workloads'),
          isVisible: this.canAccess('UI_NAV_WORKLOADS:VIEW'),
        },
        {
          to: '/deploy/workload',
          src: 'DeployDrawer.svg',
          srcSelected: 'DeploySelectedDrawer.svg',
          text: this.$t('drawer.links.deploy'),
          isExistsSubElement:
            this.canAccess('UI_SUBNAV_DEPLOY_LOG:VIEW') || this.canAccess('UI_SUBNAV_DEPLOY_DRY_RUN:VIEW'),
          isVisible: this.canAccess('UI_NAV_DEPLOY:VIEW'),
          subLinks: [
            {
              to: '/deploy/log',
              text: this.$t('drawer.links.log'),
              isVisible: this.canAccess('UI_SUBNAV_DEPLOY_LOG:VIEW'),
            },
            {
              to: '/deploy/dryRun',
              text: this.$t('drawer.links.dryRun'),
              isVisible: this.canAccess('UI_SUBNAV_DEPLOY_DRY_RUN:VIEW'),
            },
          ],
        },
        {
          to: '/labels',
          src: 'LabelsDrawer.svg',
          srcSelected: 'LabelsSelectedDrawer.svg',
          text: this.$t('drawer.links.labels'),
          isVisible: this.canAccess('UI_NAV_LABELS:VIEW'),
        },
        {
          src: 'UsersDrawer.svg',
          srcSelected: 'UsersSelectedDrawer.svg',
          text: this.$t('drawer.links.access'),
          isExistsSubElement:
            this.canAccess('UI_NAV_USERS:VIEW') ||
            this.canAccess('UI_NAV_ROLES:VIEW') ||
            this.canAccess('UI_NAV_LDAP:VIEW'),
          isVisible:
            this.canAccess('UI_NAV_USERS:VIEW') ||
            this.canAccess('UI_NAV_ROLES:VIEW') ||
            this.canAccess('UI_NAV_LDAP:VIEW'),
          subLinks: [
            {
              to: '/users',
              text: this.$t('drawer.links.users'),
              isVisible: this.canAccess('UI_NAV_USERS:VIEW'),
            },
            {
              to: '/roles',
              text: this.$t('drawer.links.roles'),
              isVisible: this.canAccess('UI_NAV_ROLES:VIEW'),
            },
            {
              to: '/ldap',
              text: this.$t('drawer.links.ldap'),
              isVisible: this.canAccess('UI_NAV_LDAP:VIEW'),
            },
          ],
        },
        {
          to: '/remotes',
          src: 'RemotesDrawer.svg',
          srcSelected: 'RemotesSelectedDrawer.svg',
          text: this.$t('drawer.links.remotes'),
          isVisible: this.canAccess('UI_NAV_REMOTE_CONNECTIONS:VIEW'),
        },
        {
          to: '/notifications',
          src: 'NotificationsDrawer.svg',
          srcSelected: 'NotificationsSelectedDrawer.svg',
          text: this.$t('drawer.links.notifications'),
          isVisible: this.canAccess('UI_NAV_NOTIFICATION:VIEW'),
        },
      ];
    },
  },
  watch: {
    getAvailableVersions(newValue) {
      this.isUpdateAvailable = newValue.length
        ? newValue.some((version) => {
            const isVersionGreaterThanCurrent = shared.isFullVersionGreaterThan(
              version.version,
              this.version.currentVersion,
            );
            return version.version !== this.version.currentVersion && isVersionGreaterThanCurrent;
          })
        : false;
    },
  },
  async mounted() {
    try {
      this.onResponsiveInverted();
      window.addEventListener('resize', this.onResponsiveInverted);
      await this.$store.dispatch('version/get_current_versions');
      // TODO: Add check for mfa enable/disable permission when it's added on backend
      await this.$store.dispatch('multi-factor-authentication/isMFAEnabled');
      this.isUpdateAvailable = this.getAvailableVersions.length
        ? this.getAvailableVersions.some((version) => {
            const isVersionGreaterThanCurrent = shared.isFullVersionGreaterThan(
              version.version,
              this.version.currentVersion,
            );
            return version.version !== this.version.currentVersion && isVersionGreaterThanCurrent;
          })
        : false;
    } catch (e) {
      this.$log.debug(e);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResponsiveInverted);
  },
  methods: {
    ...mapMutations('app', ['SET_DRAWER', 'TOGGLE_DRAWER']),
    onResponsiveInverted() {
      if (window.innerWidth < 1) {
        this.responsive = true;
        this.miniVariantWidth = 227;
      } else {
        this.responsive = false;
        this.miniVariantWidth = 80;
      }
    },
    goToSystemInfo() {
      if (this.$route.name !== 'System info') {
        this.$router.push({ name: 'System info' });
      }
    },
  },
};
</script>

<style lang="scss">
#iiotAppDrawer {
  top: 61px !important;
  background: var(--v-tertiary-base);
  height: calc(100% - 61px) !important;
  ::-webkit-scrollbar {
    width: 2px;
  }
  display: flex;
  overflow-y: auto;
  position: fixed;
  .v-list {
    height: auto;
  }
  .side {
    height: 100%;
  }
  .v-list-item {
    width: fit-content;
    font-size: 18px;
    color: var(--v-secondary-base) !important;
    height: 67px;
    box-shadow: none;
    padding: 0;
    flex: none;
  }

  .v-list-item__title {
    font-size: 18px !important;
    font-weight: 400;
    white-space: unset !important;
    padding: 0 !important;
  }

  .v-list-item--active {
    background: inherit !important;
    font-size: 18px !important;
    width: auto;
    font-weight: 400px;
    color: var(--v-primary-base) !important;
    height: 67px !important;
    border-left: 2px solid var(--v-primary-base);
  }
  .v-list-item__action {
    margin-right: 16px;
    border-left: none;
  }
  .version {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    display: flex;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--v-secondary-base);
  }
  .icon-position {
    margin-left: 15px;
  }
  .v-list-item:hover {
    width: auto;
  }

  .v-list-group__header {
    a {
      margin: 0;
    }
  }

  .v-list-group__header:hover {
    a {
      margin: 0;
    }
  }

  .v-list-group {
    a {
      .v-list-item__title {
        margin-right: 15px;
      }
    }
    .v-list-item:hover {
      width: fit-content;
    }
    .v-list-group__items {
      a {
        width: unset !important;
        border-left: none;
      }
    }
    i {
      color: var(--v-secondary-base) !important;
      width: 15px !important;
      height: 15px !important;
    }
    .v-list-item__action {
      color: var(--v-secondary-base) !important;
      margin-left: 15px !important;
      border-left: none;
    }
    .v-list-item--active {
      width: fit-content;
      border-left: none;
    }
  }
  .active-class {
    .v-list-item--active {
      border-left: 1px solid var(--v-primary-base);
    }
  }
  .v-list-item__icon.v-list-group__header__append-icon {
    min-width: 0px;
    position: absolute;
    right: 0px;
  }

  .v-list-item > a {
    width: fit-content;
    margin: 0;
  }
  .v-list-item > a:hover {
    width: fit-content;
    margin: 0;
  }
  .v-list-group--active > .v-list-group__header > .v-list-group__header__append-icon .v-icon {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .v-list-group--sub-group {
    display: flex !important;
  }
}
.v-navigation-drawer--mini-variant.v-navigation-drawer--custom-mini-variant .v-list-item {
  justify-content: end !important;
}

#iiotDrawerIconSub {
  max-width: 41px;
}

#iiotDrawerIcon {
  max-width: 41px;
}

.spreadItem {
  width: 210px !important;
}

.v-list-group--active > .v-list-group__items {
  display: block !important;
}

.v-navigation-drawer--mini-variant .v-list-item > :not(:only-child) {
  position: static !important;
  height: auto !important;
  width: auto !important;
}

.margin-right-25 {
  margin-right: 25px !important;
}
.v-navigation-drawer__content {
  #iiotDrawerNavigationList {
    height: 95%;
    border-bottom: 60px solid var(--v-tertiary-base);
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .system-info-span {
    font-size: 14px;
    font-weight: 500;
  }
}
</style>