import AuthModel from '@/model/auth/auth.model';
import mqtt from '@/plugins/mqtt';
import { AuthApiService, PermissionApiService } from '@/services/api';
import store from '@/store';
import router from '@/router';

export default {
  state: {
    user: {},
    userPermissions: {},
    isServiceUnavailable: {},
    userMFA: {},
  },
  mutations: {
    SET_AUTH_USER(state, value) {
      state.user = new AuthModel(value);
    },
    CHANGE_USER_PERMISSIONS_STATE(state, value) {
      state.userPermissions = value;
    },
    SET_SERVICE(state, value) {
      state.isServiceUnavailable = value;
    },
    SET_USER_MFA(state, value) {
      state.userMFA = value;
    },
    REMOVE_USER_MFA(state) {
      state.userMFA = {};
    },
  },
  getters: {
    isLoggedIn: (state) => () => !!state.user.username,
    getUserPermissions: (state) => state.userPermissions,
    authUser: (state) => state.user,
    isServiceUnavailable: (state) => state.isServiceUnavailable,
    userMFA: (state) => state.userMFA,
  },
  actions: {
    async login({ commit }, authData) {
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await AuthApiService.login(authData);
        if (response.user) {
          commit('CHANGE_USER_PERMISSIONS_STATE', response.user.permissions);
          commit('SET_AUTH_USER', response.user);
          localStorage.setItem('userDetails', JSON.stringify(response.user));
          localStorage.setItem(
            'session',
            JSON.stringify({ userId: response.user.id, sessionId: response.user.sessionId }),
          );
          // all cookies are set on server side.
        } else if (response.mfa) {
          commit('SET_USER_MFA', response.mfa);
        }
      } catch (e) {
        throw e;
      }
    },

    async validateMFA({ commit }, params) {
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await AuthApiService.validateMFA(params);
        commit('CHANGE_USER_PERMISSIONS_STATE', response.user.permissions);
        commit('SET_AUTH_USER', response.user);
        localStorage.setItem('userDetails', JSON.stringify(response.user));
        localStorage.setItem(
          'session',
          JSON.stringify({ userId: response.user.id, sessionId: response.user.sessionId }),
        );
      } catch (e) {
        throw e;
      }
    },

    async removeMFA({ commit }) {
      commit('REMOVE_USER_MFA');
    },

    async is_user_logged({ commit, dispatch }) {
      try {
        const loggedUser = JSON.parse(localStorage.getItem('userDetails'));
        const userId = loggedUser ? loggedUser.id : '';
        if (userId) {
          commit('SET_AUTH_USER', loggedUser);
          const response = await PermissionApiService.getPermissionByUserId(userId);
          commit('CHANGE_USER_PERMISSIONS_STATE', response);
          commit('SET_USER_MFA', {});
          return;
        }
        await dispatch('clearLocalStorage');
      } catch (e) {
        await dispatch('clearLocalStorage');
        throw e;
      }
    },

    clearLocalStorage({ commit }) {
      localStorage.removeItem('userDetails');
      localStorage.removeItem('session');
      commit('SET_AUTH_USER', {});
    },

    async logout({ dispatch }) {
      try {
        await AuthApiService.logout();
        await mqtt.disconnect();
        await store.dispatch('ldap/get_active_ldap');
        await router.push({ name: 'Login' });
      } catch (err) {
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          this.$log.debug(err);
        }
      } finally {
        dispatch('clearLocalStorage');
        await store.dispatch('node-tree/reset_state_to_default_values');
      }
    },

    async is_services_unavailable({ commit }, obj) {
      commit('SET_SERVICE', obj);
    },

    update_user({ commit }, user) {
      commit('SET_AUTH_USER', user);
    },
  },
};
